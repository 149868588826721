import { Box, Input, useToast } from '@chakra-ui/react';
import { pdf } from '@react-pdf/renderer';
import { PrintTypes } from 'print-js';
import QRCode from 'qrcode';
import React, { useState } from 'react';

import { VaccineTypes } from '../../../config';
import { VaccineText, VaccineTexts } from '../../interfaces';
import { decodeAnswers } from '../../util/decode-answers';
import { encodeAnswers } from '../../util/encode-answers';
import FilledDocument from '../formpage/Document';

let print: (source: string, type?: PrintTypes) => void;
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  print = require('print-js');
}

interface Props {
  vaccinetexts: VaccineTexts;
}

export default function ReaderPage({ vaccinetexts }: Props): JSX.Element {
  const [qrdata, setqrData] = useState('');
  const toast = useToast();

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    const decodedData = decodeAnswers(qrdata);

    if (decodedData !== null) {
      QRCode.toDataURL(
        encodeAnswers(decodedData),
        { errorCorrectionLevel: 'L' },
        (err, url) => {
          if (!err) {
            pdf(
              <FilledDocument
                answers={decodedData}
                qrcodeDataURI={url}
                vaccinetext={
                  decodedData.vaccineType !== 'X'
                    ? vaccinetexts[VaccineTypes[decodedData.vaccineType].id]
                    : null
                }
              />,
            )
              .toBlob()
              .then((res) => {
                const blobUrl = URL.createObjectURL(res);
                if (print) print(blobUrl, 'pdf');
                setqrData('');
              });
          } else {
            console.error(err);
            toast({
              title: 'Hiba!',
              description: 'Hiba történt a generáláskor',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        },
      );
    } else {
      console.error('decodedData is null');
      toast({
        title: 'Hiba!',
        description: 'Hiba történt a generáláskor',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box width={['90%', null, '80%', '60%']}>
      <form onSubmit={handleSubmit}>
        <Input
          value={qrdata}
          onChange={(e: React.FormEvent): void => {
            setqrData((e.target as HTMLInputElement).value);
          }}
          autoFocus
        />
      </form>
    </Box>
  );
}
