export const personalFields = [
  'taj',
  'lastName',
  'firstName',
  'sex',
  'birthDate',
  'birthCountry',
  'birthTown',
  'motherName',
  'country',
  'zipcode',
  'town',
  'address',
  'phone',
  'email',
];

export const additionalFiels = [
  'vaccineType',
  'chronic',
  'chronicText',
  'pills',
  'pillsText',
  'pillsmodification',
  'allergy',
  'allergyText',
  'blood',
  'anafilaxic',
  'acute',
  'fever',
  'nextMonth',
  'lastMonth',
  'lastMonthText',
  'autoimmune',
  'immunesystem',
  'para',
  'bleeding',
  'vaccine',
  'complain',
  'complainText',
  'pregnant',
  'planpregnant',
  'feed',
];
